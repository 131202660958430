import React from 'react'
import { Link } from 'gatsby'

import Footer from '../components/Footer'
import Layout from '../Layout'
import SEO from '../SEO'

const Archive: React.FC = () => {
    return (
        <div>
            <Layout currentPage="archivsuche">
                <SEO title="Was Wa(h)r - Archivsuche" />
            </Layout>

            <div className="archive archive-start">
                <Link to="/archivsuche-formular">
                    <button>{'>>>'} Zu deiner persönlichen Familiengeschichte</button>
                </Link>
                <Link to="/archivsuche-uebersicht">
                    <button>{'>>>'} Zur Übersicht aller Archive</button>
                </Link>
            </div>

            <Footer />
        </div>
    )
}

export default Archive
